<template>
  <div
    class="block-param bp-generic"
    :class="{'bp-email': channel === 'email', 'bp-sms': channel === 'sms', 'bp-landing': channel === 'landing'}"
  >
    <div class="row">
      <h2>{{ $t(`kpi.${channel}.${kpiskey}`) }}</h2>
    </div>

    <div class="infos-container">
      <!-- Important KPIS -->
      <div
        v-if="kpiskey !== 'undelivered'"
        class="kpis"
      >
        <ChartKPI
          v-for="impKpi in importantKpiList(kpisvalue)"
          :key="impKpi.key"
          :channel="channel"
          :data="{
            name: impKpi.name,
            kpi_value: impKpi.value
          }"
        />
      </div>

      <!-- Table infos -->
      <div
        v-if="!option"
        class="infos"
      >
        <div
          v-for="(kpi, index) in kpisvalue"
          :key="index"
          class="tr"
          :class="{'hidden': isImportantKpi(kpi.key) }"
        >
          <div
            v-if="!isImportantKpi(kpi.key)"
            class="td-l"
          >
            <span v-if="kpi.key === 'soft_bounces' || kpi.key === 'hard_bounces'">
              <i class="material-icons md-12 help">subdirectory_arrow_right</i>
            </span>
            {{ $t(`kpi.${channel}.${kpi.key}`) }}
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                slot="content"
                v-html="$t(`kpi.${channel}.${kpi.key}_tip`)"
              />
              <!--eslint-enable-->
              <i class="material-icons md-12 help">help_outline</i>
            </el-tooltip>
          </div>
          <div class="td-r">
            {{ formattingValue(kpi) }}
          </div>
        </div>
      </div>

      <div
        v-else-if="option === 'table'"
        class="infos"
      >
        <table-block
          :kpisvalue="kpisvalue"
          :channel="channel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChartKPI } from '@/components/Common/Chart'
import TableBlock from './Table'

export default {
  name: 'BlockTraffic',
  components: { ChartKPI, TableBlock },
  props: {
    channel: {
      type: String,
      required: true
    },
    kpiskey: {
      type: String,
      required: true
    },
    kpisvalue: {
      type: Array,
      default: () => []
    },
    kpis: {
      type: Object,
      default: () => {}
    },
    option: {
      type: [String, null],
      default: null
    }
  },
  methods: {
    /**
     * Check if it's an important KPI
     * @param  {String} key (kpi key)
     * @return {Boolean}
     */
    isImportantKpi (key) {
      return this.kpis.important.includes(key)
    },
    /**
     * Filter kpis list for important ones
     * @param  {Array} kpis (collection of kpis object)
     * @return {Array} (Should not exeed size of 2)
     */
    importantKpiList (kpis) {
      return kpis.filter(kpi => this.isImportantKpi(kpi.key))
    },
    /**
     * Formatting values to make them human readable
     */
    formattingValue (kpi) {
      if (!Object.prototype.hasOwnProperty.call(kpi, 'key')) {
        /* eslint-disable no-console */
        console.error('KPI without key', kpi)
        /* eslint-enable no-console */
        return
      }
      const isPercentage = kpi.key.includes('_rate') && kpi.value !== null
      const isNumber = !isPercentage && !isNaN(kpi.value) && kpi.value !== null
      const isNotApplicable = kpi.value === null

      switch (true) {
        case isPercentage:
          return `${this.$options.filters.round2dec(kpi.value * 100)}%`
        case isNumber:
          return this.$options.filters.formatNumber(kpi.value)
        case isNotApplicable:
          return 'N/A'
        default:
          return kpi.value
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-generic {
  .infos-container {
    display: flex;

    .kpis {
      flex: 1;
      display: flex;
      .kpi {
        margin-right: 1rem;
      }
    }

    .infos {
      .tr {
        border-bottom: solid 1px $--color-pale-blue;
        display: flex;
        padding-bottom: 0.35rem;
        margin-bottom: 0.35rem;
        .td-l {
          flex: 3;
          .help {
            height: 12px;
            line-height: 0.85;
            margin-left: 1px;
            opacity: 0.3;
            vertical-align: middle;
          }
        }
        .td-r {
          flex: 1;
          text-align: right;
          justify-self: flex-end;
        }
      }
    }

    .links-table {
      table-layout: fixed;
      width: 100%;
      font-size: 11px;

      td {
        word-wrap: break-word;
      }
      .url {
        text-align: left;
        width: 235px;
        border-bottom: solid 1px $--color-pale-blue;
        padding-bottom: 0.35rem;
        margin-bottom: 0.35rem;
        a {
          color: $--text-dark;
          display: block;
          display: -webkit-box;
          max-width: 100%;
          margin: 0 auto;
          line-height: 1.4;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .unique,
      .total {
        width: 100px;
        text-align: center;
        border-bottom: solid 1px $--color-pale-blue;
        padding-bottom: 0.5rem;
        margin-bottom: 0.35rem;
      }
    }
  }
}
</style>
