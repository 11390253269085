<template>
  <div class="card block">
    <div class="picto">
      <img
        :src="require(`../../../assets/svg/pictos/${channelName}.svg`)"
        alt="Channel image"
      >
    </div>
    <div class="category-title">
      <h2>{{ $t(`dashboard.card_${channelName}.title`) }}</h2>
    </div>
    <ul
      v-if="loading"
      class="overview-table"
    >
      <li>
        <LoaderSummary style="width: 100%; height: 36px" />
      </li>
      <li>
        <LoaderSummary style="width: 100%; height: 36px" />
      </li>
      <li>
        <LoaderSummary style="width: 100%; height: 36px" />
      </li>
    </ul>
    <ul
      v-else-if="formattedCampaigns.length"
      class="overview-table"
    >
      <li
        v-for="(campaign, index) in formattedCampaigns.slice(0, 3)"
        :key="index"
      >
        <router-link :to="{name: `${channelName}-detail-overview`, params: { campaignId: campaign.id }}">
          <p>
            <el-tooltip
              v-if="campaign.campaign_name && campaign.campaign_name.length > titleBreakPoint"
              :content="campaign.campaign_name"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
            >
              <strong>
                {{ campaign.campaign_name | truncate(titleBreakPoint) }}
              </strong>
            </el-tooltip>
            <strong v-else>
              {{ campaign.campaign_name | truncate(titleBreakPoint) }}
            </strong>
          </p>
          <p class="campaign-stats">
            <span v-if="campaign.hasOwnProperty('sending_date')">
              {{ $t('dashboard.sent') }}
              {{ campaign.sending_date | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }},
            </span>
            <span v-if="campaign.hasOwnProperty('open_rate') && campaign.open_rate !== null">
              {{ campaign.open_rate * 100 | round2dec }}%
              {{ $t('dashboard.open') }},
            </span>
            <span
              v-if="campaign.hasOwnProperty('delivery_rate') && campaign.delivery_rate !== null && channelName !== 'email'"
            >
              {{ campaign.delivery_rate * 100 | round2dec }}%
              {{ $t('dashboard.delivery') }},
            </span>
            <span v-if="campaign.hasOwnProperty('click_rate') && campaign.click_rate !== null">
              {{ campaign.click_rate * 100 | round2dec }}%
              {{ $t('dashboard.click') }}
            </span>
          </p>
        </router-link>
      </li>
    </ul>
    <p
      v-else
      class="overview-table no-campaign"
    >
      {{ $t('dashboard.no_campaign') }}
    </p>
    <router-link :to="{name: `${channelName}-list`}">
      <el-button type="primary">
        {{ $t(`dashboard.card_${channelName}.label`) }}
      </el-button>
    </router-link>
  </div>
</template>

<script>
import { LoaderSummary } from '@/components/Common/Loader'

export default {
  name: 'Summary',
  components: {
    LoaderSummary
  },
  props: {
    channelName: {
      type: String,
      required: true
    },
    campaigns: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      titleBreakPoint: 38,
    }
  },
  computed: {
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    formattedCampaigns () {
      const result = this.campaigns.reduce((acc, campaign) => {
        if (campaign.idms) {
          return acc.concat(campaign.idms.map(c => c))
        }
        return acc.concat(campaign);
      }, []).sort((a, b) => {
        return new Date(a.sending_date) - new Date(b.sending_date)
      })
      return result
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.card {
  flex: 1;
  padding-top: 11px !important;
  max-width: 396px;
  height: 511px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .picto {
    padding: 2rem 0;
    img {
      height: 58px;
    }
  }
  .category-title {
    height: 60px;
    h2 {
      text-align: center;
      padding-bottom: 2rem;
    }
  }
  .overview-table {
    flex-grow: 1;
    width: 100%;
    a {
      text-decoration: none;
      color: $--text-light;
    }
  }
  .no-campaign {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 1rem;
      width: 100%;
      color: $--text-light;
      border-bottom: solid 1px $--color-light-gray;
      transition: background-color 0.4s;

      strong {
        font-size: 14px;
        color: $--text-dark;
      }
      &:hover {
        background-color: $--color-bg-light-gray;
      }
      .campaign-stats {
        margin-top: 0.4em;
        font-size: 12px;
      }
    }
  }
  button {
    // margin: auto;
    justify-self: flex-end;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 11px;
    background: repeating-linear-gradient(
        45deg,
        $--color-primary 0%,
        $--color-primary 33%,
        $--color-secondary 33%,
        $--color-secondary 66%,
        $--color-primary 66%,
        $--color-primary 100%
      ),
      $--color-primary;
    background-repeat: no-repeat;
    background-position: left center, right top;
  }
  @media only screen and (max-width: $--mobile-width) {
    margin: 0 0 2rem 0 !important;
    width: 100%;
  }
}
</style>
