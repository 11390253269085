<template>
  <div
    v-if="!hasSplitView"
    class="block-param bp-amplifier"
    :class="{'bp-email': channel === 'email'}"
  >
    <div class="row">
      <h2>{{ $t(`kpi.${channel}.${kpiskey}`) }}</h2>
    </div>
    <div>
      <span>{{ $t(`kpi.${channel}.ampli_definition`) }}</span>
    </div>
    <div class="container">
      <!-- Left Side -->
      <div class="container-left">
        <!-- Important KPIS -->
        <div class="kpis">
          <ChartKPI
            v-for="impKpi in importantKpiList(kpisvalue)"
            :key="impKpi.key"
            :channel="channel"
            :data="{
              name: impKpi.name,
              kpi_value: impKpi.value
            }"
          />
        </div>
        <div class="infos">
          <div
            v-for="(kpi, index) in kpisvalue"
            :key="index"
            :class="{'hidden': isImportantKpi(kpi.key) || kpi.key === 'image_url' }"
            class="tr"
          >
            <div
              v-if="!isImportantKpi(kpi.key)"
              class="td-l"
            >
              {{ $t(`kpi.${channel}.${kpi.key}`) }}

              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                transition="none"
                :open-delay="400"
                :hide-after="0"
              >
                <div slot="content">
                  {{ $t(`kpi.${channel}.${kpi.key}_tip`) }}
                </div>
                <i class="material-icons md-12 help">help_outline</i>
              </el-tooltip>
            </div>
            <div class="td-r">
              {{ formattingValue(kpi) }}
            </div>
          </div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="container-right">
        <div class="amplifier-img">
          <img
            class="img"
            alt="Amplifier image"
            :src="imageSrc"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartKPI } from '@/components/Common/Chart'

export default {
  components: { ChartKPI },
  props: {
    channel: {
      type: String,
      required: true
    },
    kpiskey: {
      type: String,
      required: true
    },
    kpisvalue: {
      type: Array,
      default: () => []
    },
    kpis: {
      type: Object,
      default: () => {}
    },
    option: {
      type: [String, null],
      default: null
    },
    imageSrc: {
      type: String,
      default: null
    }
  },
  computed: {
    hasSplitView () {
      const split = this.$store.getters['emailCampaign/currentSplit']
      return !!split.length && split[0].id !== ''
    }
  },
  methods: {
    /**
     * Check if it's an important KPI
     * @param  {String} key (kpi key)
     * @return {Boolean}
     */
    isImportantKpi (key) {
      return this.kpis.important.includes(key)
    },
    /**
     * Filter kpis list for important ones
     * @param  {Array} kpis (collection of kpis object)
     * @return {Array} (Should not exeed size of 2)
     */
    importantKpiList (kpis) {
      return kpis.filter(kpi => this.isImportantKpi(kpi.key))
    },

    /**
     * Formatting values to make them human readable
     */
    formattingValue (kpi) {
      if (!Object.prototype.hasOwnProperty.call(kpi, 'key')) {
        /* eslint-disable no-console */
        console.error('KPI without key', kpi)
        /* eslint-enable no-console */
        return
      }
      const isPercentage = kpi.key.includes('_rate') && kpi.value !== null
      const isNumber = !isPercentage && !isNaN(kpi.value) && kpi.value !== null
      const isNotApplicable = kpi.value === null

      switch (true) {
        case isPercentage:
          return `${this.$options.filters.round2dec(kpi.value * 100)}%`
        case isNumber:
          return this.$options.filters.formatNumber(kpi.value, '0a')
        case isNotApplicable:
          return 'N/A'
        default:
          return kpi.value
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.bp-amplifier {
  .row {
    margin-bottom: 0;
  }

  .container {
    display: flex;
    margin-top: 1.4rem;

    .container-left {
      display: flex;
      flex-direction: column;

      .kpis {
        flex: 1;
        display: flex;
        margin-bottom: 1.4rem;
        .kpi {
          margin-right: 1rem;
        }
      }
    }
    .container-right {
      .amplifier-img {
        margin-left: 2rem;
        .img {
          width: 270px;
          height: 225px;
        }
      }
    }
    .infos {
      .tr {
        border-bottom: solid 1px $--color-pale-blue;
        display: flex;
        padding-bottom: 0.35rem;
        margin-bottom: 0.35rem;
        .td-l {
          .help {
            height: 12px;
            line-height: 0.85;
            margin-left: 1px;
            opacity: 0.3;
            vertical-align: middle;
          }
        }
        .td-r {
          flex: 1;
          text-align: right;
          justify-self: flex-end;
        }
      }
    }
  }
}
</style>
