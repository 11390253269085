<template>
  <section class="tableview">
    <header class="page-header">
      <div class="row">
        <Breadcrumb :items="breadcrumbs" />
        <el-button
          type="secondary"
          @click="goToDetail()"
        >
          <i class="el-material-icons">close</i>
          {{ $t('BUTTON.CLOSE_TABLE_VIEW') }}
        </el-button>
      </div>

      <HeaderSelectIdm
        v-if="relatedCampaigns.length > 1"
        :campaigns="relatedCampaigns"
        :campaign-type="campaignType"
        @change-campaign="handleChangeCampaign"
      />

      <el-alert
        v-if="lengthAlert"
        :title="$t('alerts.fifteen_sendings')"
        type="warning"
        show-icon
      />

      <div
        class="table-header"
        :class="{'sticky': isTop}"
      >
        <div class="first-row">
          <div class="segments">
            <SegmentSelector
              :current-split="currentSplit"
              :header-splits="headerSplits"
              @updated-splits="updateSplits"
            />
            <SegmentTags
              :current-splits="splitSelection"
              :header-splits="headerSplits"
              @update-tag="filterSplits"
            />
          </div>
          <div class="actions">
            <DatePicker
              v-if="isNotOneShot"
              :channel="channel"
              @updated="refreshSplits"
            />
            <DownloadButton
              :disabled="splits.length === 0"
              :loading="downloadLoading"
              :is-list="true"
              @click-download="downloadExport"
            />
          </div>
        </div>
      </div>
    </header>

    <div
      class="table-border"
      :class="{'sticky-table-border': isTop}"
    >
      <div class="container">
        <!-- FIRST FIXED COLUMN -->
        <div class="col_segment col_fixed">
          <!-- NAME -->
          <h3
            class="cell theader"
            :class="{'th-sticky': isTop}"
            :style="{top: (scrollTop - offset - 54) + 'px' }"
          >
            {{ $t('table.header.description') }}
          </h3>
          <!-- SEND DATES -->
          <h3 class="cell">
            {{ $t('kpi.push.start_date') }}
            <el-tooltip
              v-if="shouldDisplayTooltip('start_date')"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                slot="content"
                v-html="getDefinition('start_date')"
              />
              <!-- eslint-enable vue/no-v-html -->
              <i class="material-icons md-12 help">help_outline</i>
            </el-tooltip>
          </h3>
          <h3 class="cell">
            {{ $t('kpi.push.end_date') }}
            <el-tooltip
              v-if="shouldDisplayTooltip('end_date')"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                slot="content"
                v-html="getDefinition('end_date')"
              />
              <!-- eslint-enable vue/no-v-html -->
              <i class="material-icons md-12 help">help_outline</i>
            </el-tooltip>
          </h3>
          <!-- DELIVERY -->
          <h3 class="cell inter">
            {{ $t('kpi.push.delivery') }}
          </h3>
          <h3
            v-for="(sub, i) in firstSplit.delivery"
            :key="'del'+i"
            class="cell"
          >
            <i
              v-if="['soft_bounces', 'hard_bounces'].indexOf(i) > -1"
              class="material-icons md-12"
            >
              subdirectory_arrow_right
            </i>
            {{ $t('kpi.push.' + i) }}
            <el-tooltip
              v-if="shouldDisplayTooltip(i)"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                slot="content"
                v-html="getDefinition(i)"
              />
              <!-- eslint-enable vue/no-v-html -->
              <i class="material-icons md-12 help">help_outline</i>
            </el-tooltip>
          </h3>
          <!-- REACTIONS -->
          <h3 class="cell inter">
            {{ $t('kpi.push.reactions') }}
          </h3>
          <h3
            v-for="(sub, i) in firstSplit.reactions"
            :key="'rea'+i"
            class="cell"
          >
            {{ $t('kpi.push.' + i) }}
            <el-tooltip
              v-if="shouldDisplayTooltip(i)"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                slot="content"
                v-html="getDefinition(i)"
              />
              <!-- eslint-enable vue/no-v-html -->
              <i class="material-icons md-12 help">help_outline</i>
            </el-tooltip>
          </h3>

          <!-- ALL CONVERSIONS -->
          <h3 class="cell inter">
            {{ $t('conversions.labels.all') }}
          </h3>
          <h3
            v-for="sub in splitConversionsData"
            :key="'all_conversion_' + sub"
            class="cell"
          >
            {{ $t('kpi.email.' + sub) }}
            <el-tooltip
              v-if="shouldDisplayTooltip(sub)"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                slot="content"
                v-html="getDefinition(sub)"
              />
              <!-- eslint-enable vue/no-v-html -->
              <i class="material-icons md-12 help">help_outline</i>
            </el-tooltip>
          </h3>
          <!-- CONVERSIONS BY TYPE -->
          <div
            v-for="(type, i) in conversionTypes"
            :key="'conversion_type_' + i"
          >
            <h3
      
              class="cell inter"
            >
              {{ $t(`conversions.labels.${type.name}`) }}
            </h3>
            <h3
              v-for="sub in splitConversionsData"
              :key="'conversion_type_label_' + sub"
              class="cell"
            >
              {{ $t('kpi.email.' + sub) }}
              <el-tooltip
                v-if="shouldDisplayTooltip(sub)"
                effect="dark"
                placement="top"
                transition="none"
                :open-delay="400"
                :hide-after="0"
              >
                <!-- eslint-disable vue/no-v-html -->
                <div
                  slot="content"
                  v-html="getDefinition(sub)"
                />
                <!-- eslint-enable vue/no-v-html -->
                <i class="material-icons md-12 help">help_outline</i>
              </el-tooltip>
            </h3>
          </div>
        </div>

        <!-- CONTENT -->
        <div
          v-for="(col, index) in splits"
          :key="'k' + index"
          class="col_segment"
        >
          <!-- NAME -->
          <h3
            class="cell theader"
            :class="{'th-sticky': isTop}"
            :style="{top: (scrollTop - offset - 54) + 'px' }"
          >
            <span v-if="col.name === 'Total'">{{ col.name }} - {{ currentDisplayedName }}</span>
            <span v-else-if="currentSplit[0] && currentSplit[0].cat === 'sendings'">
              {{ col.name | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }}
            </span>
            <span v-else-if="currentSplit[0] && currentSplit[0].cat === 'extra_data'">{{ col.name }}</span>
            <span v-else>{{ col.name }}</span>
          </h3>

          <!-- SEND DATES -->
          <p class="cell">
            {{ col.start_date | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }}
          </p>
          <p class="cell">
            {{ col.end_date | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }}
          </p>

          <!-- DELIVERY -->
          <div class="cell inter" />
          <div
            v-for="(sub, i) in col.delivery"
            :key="'d_' + index + '_' + i"
            class="cell"
          >
            <p v-if="sub !== null">
              <span v-if="i.includes('_rate')">{{ sub * 100 | round2dec }}%</span>
              <span v-else>{{ sub | formatNumber }}</span>
            </p>
            <p v-else>
              -
            </p>
          </div>

          <!-- REACTIONS -->
          <div class="cell inter" />
          <div
            v-for="(sub, i) in col.reactions"
            :key="`r_${index}_${i}`"
            class="cell"
          >
            <p v-if="sub !== null">
              <span v-if="i.includes('_rate')">{{ sub * 100 | round2dec }}%</span>
              <span v-else>{{ sub | formatNumber }}</span>
            </p>
            <p v-else>
              -
            </p>
          </div>

          <!-- ALL CONVERSIONS -->
          <div class="cell inter" />
          <div
            v-for="sub in formatColConversions(col.conversion_total)"
            :key="`conversion_total_value_${index}_${sub.key}`"
            class="cell"
          >
            <p v-if="sub.value !== null">
              <span v-if="sub.key.includes('_rate')">{{ sub.value * 100 | round2dec }}%</span>
              <span v-else-if="['average_revenue_per_send', 'total_revenue', 'average_basket'].indexOf(sub.key) > -1">
                {{ sub.value | formatNumber("0,0.00") }}{{ clientCurrency }}
              </span>
              <span v-else>{{ sub.value | formatNumber }}</span>
            </p>
            <p v-else>
              -
            </p>
          </div>
          <!-- CONVERSION BY TYPE -->
          <div
            v-for="conversion in col.conversions"
            :key="'conversion_types_' + conversion.conversion_type_id"
          >
            <div
              class="cell inter"
            />
            <div
              v-for="sub in formatColConversions(conversion)"
              :key="`conversion_total_value_${index}_${sub.key}`"
              class="cell"
            >
              <p v-if="sub.value !== null">
                <span v-if="sub.key.includes('_rate')">{{ sub.value * 100 | round2dec }}%</span>
                <span v-else-if="['average_revenue_per_send', 'total_revenue', 'average_basket'].indexOf(sub.key) > -1">
                  {{ sub.value | formatNumber("0,0.00") }}{{ clientCurrency }}
                </span>
                <span v-else>{{ sub.value | formatNumber }}</span>
              </p>
              <p v-else>
                -
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import config from '@/config'
import { isoDateString } from '@/helpers/isoDate'
import {
  SegmentTags,
  SegmentSelector
} from '@/components/Common/Segment'
import {
  DatePicker,
  DownloadButton
} from '@/components/Common/Form'
import Breadcrumb from '@/components/Common/Breadcrumb'
import HeaderSelectIdm from '@/components/Common/SelectIdm'

export default {
  name: 'ViewsPushTable',
  components: {
    SegmentTags,
    SegmentSelector,
    DatePicker,
    DownloadButton,
    Breadcrumb,
    HeaderSelectIdm
  },
  data () {
    return {
      scrollTop: 0,
      offset: 0,
      isTop: false,
      channel: 'push',
      splitSelection: []
    }
  },
  computed: {
    campaignId () {
      const parsed = parseInt(this.$route.params.campaignId)
      if (isNaN(parsed)) {
        return this.$route.params.campaignId
      }
      return parsed
    },
    downloadLoading () {
      return this.$store.getters['pushCampaign/downloadLoading']
    },
    currentSplit () {
      return this.$store.getters['pushCampaign/currentSplit']
    },
    isNotOneShot () {
      return this.campaign.campaign_type !== config.PUSH_CHANNEL_TAGS.one_shot
    },
    currentDisplayedName () {
      if (this.currentSplit[0]) {
        return `${this.$t('detail.' + this.currentSplit[0].cat)} (${this.headerSplits[this.currentSplit[0].cat].total})`
      }
      return ''
    },
    campaignMeta () {
      return this.$store.getters['pushCampaign/headerMeta'] || {}
    },
    relatedCampaigns () {
      if (!Object.keys(this.campaignMeta).length || !this.campaignMeta["related_campaigns"]) {
        return []
      }
      return [this.campaignId, ...this.campaignMeta["related_campaigns"]]
    },
    groupName () {
      return this.campaign.group ? `(${this.campaign.group})` : ''
    },
    rangeDate () {
      const { start, end } = this.$store.getters['app/rangeDate']
      const tz = this.$store.getters['session/user'].timezone || 'UTC'

      return {
        start: isoDateString(start, tz),
        end: isoDateString(end, tz, true),
      }
    },
    campaign () {
      return this.$store.getters['pushCampaign/campaign'] || {}
    },
    rangeDateRaw () {
      return this.$store.getters['app/rangeDate']
    },
    headerSplits () {
      return this.$store.getters['pushCampaign/headerSplits']
    },
    campaignType () {
      if (this.campaign.campaign_type === config.PUSH_CHANNEL_TAGS.automatic) {
        return 'automatic'
      } else if (this.campaign.campaign_type === config.PUSH_CHANNEL_TAGS.transactional) {
        return 'transactional'
      }
      return 'one_shot'
    },
    lengthAlert () {
      if (this.currentSplit[0] && this.currentSplit[0].cat) {
        const hs = this.headerSplits
        if (hs[this.currentSplit[0].cat]) {
          const segLgt = hs[this.currentSplit[0].cat].total
          return segLgt > 15 || false
        }
      }
      return false
    },
    splits () {
      // Put the "total col" in 1st position
      const originalSplits = this.$store.getters['pushCampaign/splits']
      let newOrderedSplits = []

      originalSplits.forEach(element => {
        if (element.id === 'total_splits') {
          newOrderedSplits.unshift(element)
        } else {
          newOrderedSplits.push(element)
        }
      })
      return newOrderedSplits
    },
    firstSplit () {
      return this.splits.length ? this.splits[0] : {}
    },
    splitConversionsData () {
      if (this.splits.length) {
        return  ['total_conversions', 'total_revenue', 'conversion_rate', 'average_revenue_per_send', 'average_basket', 'articles', 'buyers' ]
      }
      return []
    },
    clientCurrency () {
      return this.$store.getters['session/client'].currency
    },
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    breadcrumbs () {
      const capitalize = this.$options.filters.capitalize
      return [
        {
          state: 'push-list',
          name: `${capitalize(this.$tc('global.push_notif_campaign', 2))} ${this.groupName}`
        },
        {
          state: 'push-detail-overview',
          stateParams: { campaignId: this.campaignId },
          name: `${this.$route.params.campaignId} - ${this.campaign.campaign_name}`
        }
      ]
    },
    conversionTypes() {
      return this.$store.getters['conversions/conversionTypes']
    }
  },
  async created () {
    // NOTE(@cky): when you come from push view this value is already filled copy this to save it
    this.resetSelection()

    if (this.campaign.campaign_id !== this.campaignId) {
      const response = await this.$store.dispatch('pushCampaign/getCampaign', this.campaignId)
      if (response.status != 200) {
        if (response.status >= 500) {
          this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
        } else {
          this.$message({
            showClose: true,
            center: true,
            duration: 0,
            message: this.$t('global.alert_no_campaign'),
          })
        }
        this.$router.push({ name: 'home' })
        return
      }
    }

    // Get campaign conversion types if null 
    if (this.conversionTypes.length === 0) {
      this.$store.dispatch('conversions/getConversionTypes')
    }

    // Inject date if filter has been activated on this campaign from url
    for (let key in this.$route.query) {
      switch (key) {
      case 'start_date':
        this.$store.dispatch('app/setStartDate', this.$route.query[key])
        break
      case 'end_date':
        this.$store.dispatch('app/setEndDate', this.$route.query[key])
        break
      }
    }
    this.refreshSplits()
    document.getElementsByClassName('wrapper')[0].addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    document.getElementsByClassName('wrapper')[0].removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    refreshSplits () {
      const params = { }
      if (this.campaign.campaign_type !== config.PUSH_CHANNEL_TAGS.one_shot) {
        params.start_date = this.rangeDate.start
        params.end_date = this.rangeDate.end
      }
      this.$store.dispatch('pushCampaign/getHeaderMeta', {
        id: this.campaignId,
        params: params
      })
      this.$store.dispatch('pushCampaign/getHeaderSplits', {
        id: this.campaignId,
        params: params
      })
      if (this.currentSplit.length > 0) {
        this.loadData()
      }
    },
    loadData () {
      const params = {}
      if (this.campaign.campaign_type !== config.PUSH_CHANNEL_TAGS.one_shot) {
        params.start_date = this.rangeDate.start
        params.end_date = this.rangeDate.end
      }
      params.split = this.splitSelection.map(item => item.id).join('|')
      this.$store.dispatch('pushCampaign/getSplits', {
        id: this.campaignId,
        params: params
      })
      this.urlParams()
    },
    updateSplits (splits) {
      this.$store.dispatch('pushCampaign/selectSplit', splits)
      this.resetSelection()
      if (this.currentSplit.length > 0) {
        this.loadData()
      } else {
        this.$store.dispatch('pushCampaign/resetSplits')
      }
      this.defaultSplits = this.currentSplit
    },
    filterSplits (ids) {
      const newSelection = this.currentSplit.filter(
        item => ids.includes(item.id)
      )
      if (newSelection.length <= config.MAX_SPLIT_DISPLAY) {
        this.splitSelection = newSelection
        if (this.currentSplit.length > 0) {
          this.loadData()
        } else {
          this.$store.dispatch('pushCampaign/resetSplits')
        }
      }
    },
    resetSelection() {
      this.splitSelection = this.currentSplit.slice(
        0, config.MAX_SPLIT_DISPLAY
      ) || []
    },
    urlParams () {
      let queryParams = {}
      if (this.isNotOneShot) {
        queryParams['start_date'] = this.rangeDateRaw.start
        queryParams['end_date'] = this.rangeDateRaw.end
      }
      this.$router.replace({ query: queryParams }).catch(() => {})
    },
    handleScroll (event) {
      const offsetY = this.lengthAlert ? 172 : 112
      this.offset = offsetY
      this.isTop = event.target.scrollTop > offsetY
      this.scrollTop = event.target.scrollTop
    },
    downloadExport () {
      const params = {}
      if (this.campaign.campaign_type !== config.PUSH_CHANNEL_TAGS.one_shot) {
        params.start_date = this.rangeDate.start
        params.end_date = this.rangeDate.end
      }
      this.$store.dispatch('pushCampaign/exportCampaignSplits', {
        id: this.campaignId,
        params: params
      })
    },
    getDefinition (kpi) {
      return this.$t(`kpi.push.${kpi}_tip`)
    },
    goToDetail () {
      this.$router.push({ name: 'push-detail-overview', params: { campaignId: this.campaignId }})
    },
    shouldDisplayTooltip (kpi) {
      return kpi !== 'pn_sent'
    },
    formatColConversions(conversions, split_index, conversion_type_id) {
      let data = Object.assign({}, conversions)
      // Just in case the js messes up the order, find the correct conversion through the conversion_type_id
      if (conversion_type_id && split_index && conversion_type_id !== conversions.conversion_type_id) {
        const conversion = this.splits[split_index].conversions.find(c => c.conversion_type_id === conversion_type_id)
        data = Object.assign({}, conversion)
      }
      return [
        { key:'total_conversions', value: data.total_conversions },
        { key:'total_revenue', value: data.total_revenue },
        { key:'conversion_rate', value: data.conversion_rate },
        { key:'average_revenue_per_send', value: data.average_revenue_per_send },
        { key:'average_basket', value: data.average_basket },
        { key:'articles', value: data.articles },
        { key:'buyers', value: data.buyers },
      ]
    },
    handleChangeCampaign(campaignId) {
      this.$router.push({ params: { campaignId: campaignId }, query: this.$router.currentRoute.query })
      this.refreshSplits()
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.el-tooltip__popper {
  max-width: 250px !important;
}
.tableview {
  margin: auto;
  max-width: $--max-width;
  padding: 0 50px;
  position: relative;
  $padding-table: 1px;
  $cell-width: 256px;
  $cell-height: 50px;
  $cell-padding: 20px;

  header {
    .el-date-editor {
      margin-right: 0.5rem;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column;
    }
  }
  .table-header {
    position: relative;
    margin-bottom: 0;
    background-color: #ffffff;
    border: 1px solid #dcdfe6;
    border-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .first-row {
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column;
    }
  }
  .sticky {
    position: fixed;
    top: 48px;
    z-index: 2;
    width: 100%;
    max-width: $--max-width;
    padding: 0 50px;
  }
  .sticky-table-border {
    padding-top: 130px;
  }
  .th-sticky {
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: #fff;
  }
  .el-alert {
    margin-bottom: 2rem;
  }
  hr {
    border: none;
    color: #ebeef5;
    background-color: #ebeef5;
    height: 1px;
    margin: 2.5rem 0;
  }
  .segments,
  .actions {
    display: flex;
  }
  .table-border {
    margin: auto;
    margin-bottom: 3rem;
    position: relative;
    background-color: #fff;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: solid 1px #dcdfe6;
    border-top: none;
    overflow: hidden;
  }
  .container {
    display: flex;
    margin-left: $cell-width - 1;
    overflow-x: scroll;

    .col_segment {
      position: relative;
      flex: 1;
      font-size: 12px;

      h3,
      p {
        font-size: 12px;
      }

      .cell {
        background-color: #fff;
        min-width: $cell-width;
        height: $cell-height;
        overflow: hidden;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-bottom: solid 1px #ebeef5;
        border-right: solid 1px #ebeef5;

        .graph {
          padding: 1rem;
          width: 256px;
          height: 256px;
        }
        .help {
          opacity: 0.3;
          padding-left: 0.35rem;
          padding-top: 1px;
        }
      }
      .inter {
        background-color: #f5f7fa;
        border-right: none;
      }
      .cell-links {
        height: 100px;
        padding-top: 1rem;
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: $cell-padding;

        ul {
          list-style: none;
          padding-left: 0;
          li {
            margin-bottom: 1rem;
          }
        }
      }
      .cell-charts {
        height: 256px;
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .cell-charts-title {
        display: flex;
        flex-direction: column;
        height: 256px;
        padding-top: 1rem;
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;

        .el-select {
          min-width: 200px;
          margin-bottom: 2rem;
        }
      }
    }

    .col_fixed {
      position: absolute;
      width: $cell-width;
      z-index: 1;
      left: 0;
      top: auto;
      .cell {
        justify-content: flex-start;
        text-align: left;
        padding-left: $cell-padding;
      }
    }
  }
}
</style>
