<template>
  <el-card
    shadow="hover"
    class="card-item"
    @mouseenter.native="onMouseEnter"
    @mouseleave.native="onMouseLeave"
  >
    <div
      slot="header"
      class="card-item-header"
      :class="{ hover: hoverCard }"
    >
      <div
        class="card-item-thumbnail"
        :style="{ 'background-image': 'url(' + require('@/assets/svg/reports/' + item.thumbnail) + ')' }"
      />

      <el-button
        class="card-item-view-btn"
        type="primary"
        @click.native="viewReport"
      >
        <span>
          {{ $t('BUTTON.VIEW_REPORT') }}
        </span>
      </el-button>
    </div>
    <div class="card-item-body">
      <el-tooltip
        class="item"
        effect="dark"
        placement="bottom-start"
        transition="none"
        :open-delay="400"
        :hide-after="0"
        :content="item.name"
      >
        <p>{{ item.name }}</p>
      </el-tooltip>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'ReportCard',
  props: {
    item: { type: Object, required: true }
  },
  data () {
    return {
      hoverCard: false
    }
  },
  methods: {
    viewReport () {
      this.$router.push({
        name: 'reports-detail',
        params: {
          reportId: this.item.id
        }
      })
    },
    onMouseEnter () {
      this.hoverCard = true
    },
    onMouseLeave () {
      this.hoverCard = false
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
$--card-item-border: 1px solid #DCDFE6;
$--card-item-width: 100%;
$--card-item-min-width: 300px;
$--card-item-height: 300px;
$--card-item-max-width: 400px;
$--card-item-header-height: 200px;
$--card-item-body-height: calc(#{$--card-item-height} - #{$--card-item-header-height});

.el-card.card-item {
  height: $--card-item-height;
  width: $--card-item-width;
  min-width: $--card-item-min-width;
  max-width: $--card-item-max-width;
  border: $--card-item-border;
  flex: 1;
  margin: 15px 0;

  .el-card__header {
    width: $--card-item-width;
    height: $--card-item-header-height;
    padding: 0;
    border-bottom: none;

    .card-item-header {
      height: 100%;
      position: relative;

      .card-item-thumbnail {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: left top;

        &:before {
          position: absolute;
          content: " ";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          visibility: hidden;
          z-index: 0;
          opacity: 0;
          -webkit-transition: all .2s;
          transition: all .2s;
          background-color: rgba(0,0,0,.4);
        }
      }

      .el-button.card-item-view-btn {
        border: none;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        display: inline-block;
        left: 50%;
        top: 50%;
        // RIGHT to fix bug IE 11
        right: auto;
        transform: translateX(-50%) translateY(-50%);
        transition: opacity 0.1s;
      }
    }

    .card-item-header:hover, .card-item-header.hover {
      .card-item-thumbnail::before {
        opacity: 1;
        visibility: visible;
      }

      .card-item-view-btn {
        cursor: pointer;
        visibility: visible;
        opacity: 1;
        transition: all 0.2s;
      }
    }
  }

  .el-card__body {
    height: $--card-item-body-height;
    padding: 10px 25px;

    .card-item-body {
      font-size: 16px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
    }
  }
}
</style>
