<template>
  <div
    class="block devices"
  >
    <h3>
      {{ chartTitle }}
    </h3>
    <div class="graph-container">
      <div class="graph">
        <span>
          {{ $t('labels.opens') }}
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            transition="none"
            :open-delay="400"
            :hide-after="0"
          >
            <div slot="content">
              {{ openTooltip }}
            </div>
            <i class="material-icons md-12 help">help_outline</i>
          </el-tooltip>
        </span>
        <div
          v-if="chartOpens && chartOpens.labels.length > 0 && hasAtLeastOneValue(chartOpens.datasets[0]['data'])"
          class="graph-data"
        >
          <ChartDonut
            :chart-data="chartOpens"
            :options="chartOptions"
          />
        </div>
        <div
          v-else
          class="graph-data no-data"
        >
          <strong>{{ $t('detail.no_data') }}</strong>
        </div>
      </div>
      <div class="graph">
        <span>
          {{ $t('labels.clicks') }}
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            transition="none"
            :open-delay="400"
            :hide-after="0"
          >
            <div slot="content">
              {{ clickTooltip }}
            </div>
            <i class="material-icons md-12 help">help_outline</i>
          </el-tooltip>
        </span>

        <div
          v-if="chartClicks && chartClicks.labels.length > 0 && hasAtLeastOneValue(chartClicks.datasets[0]['data'])"
          class="graph-data"
        >
          <ChartDonut
            :chart-data="chartClicks"
            :options="chartOptions"
          />
        </div>
        <div
          v-else
          class="graph-data no-data"
        >
          <strong>{{ $t('detail.no_data') }}</strong>
        </div>
      </div>
    </div>
    <div class="legende-container">
      <GraphAutoLegend
        :labels="chartLegends"
        position="bottom"
        :tooltips="true"
      />
    </div>
  </div>
</template>

<script>
import { ChartDonut } from '@/components/Common/Chart'
import { GraphAutoLegend } from '@/components/Common/Graph'

export default {
  name: 'Devices',
  components: {
    ChartDonut,
    GraphAutoLegend,
  },
  props: {
    chartTitle : {
      type: String,
      required: true
    },
    clickTooltip : {
      type: String,
      required: true
    },
    openTooltip : {
      type: String,
      required: true
    },
    chartClicks: {
      type: Object,
      required: true
    },
    chartOpens: {
      type: Object,
      required: true
    },
    chartLegends: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const meta = dataset._meta[Object.keys(dataset._meta)[0]]
              const total = meta.total
              const currentValue = dataset.data[tooltipItem.index]
              const percentage = parseFloat((currentValue / total * 100).toFixed(1))
              return percentage + '%'
            },
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index]
            }
          }
        }
      }
    }
  },
  methods: {
    hasAtLeastOneValue (datasets) {
      return datasets.some(data => data !== 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.devices {
  width: 49%;
  min-width: 400px;
  .graph-container {
    display: flex;
    justify-content: space-around;
    .graph {
      text-align: center;
      padding: 2rem 0rem;
      .graph-data {
        margin-top: 10px;
        width: $--graph-width;
        height: $--graph-width;
      }
      span {
        i {
          display: inline-block;
          color: $--text-light;
          font-size: 12px;
          height: 15px;
          vertical-align: text-bottom;
        }
      }
    }
  }

  .legende-container {
    width: 100%;
  }
}

@media only screen and (max-width: $--mobile-width) {
  .devices {
    width: 100%;
    margin-top: 2rem;
  }
}
</style>
