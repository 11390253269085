<template>
  <div class="table-qr">
    <el-table
      :data="data"
      style="width: 100%"
      :default-sort="{prop: 'created_at', order: 'descending'}"
      @sort-change="orderBy"
      @row-click="goToDetail"
      @cell-mouse-enter="showOpen"
      @cell-mouse-leave="currentOverId = ''"
    >
      <!-- NO DATA -->
      <div slot="empty">
        <TableEmptyData :empty-label="emptyLabel" />
      </div>

      <!-- GROUP -->
      <el-table-column
        width="60"
        prop="group"
        :label="$t('table.header.group')"
      >
        <template slot-scope="scopeg">
          <span
            v-if="scopeg.row.group"
            class="pictify"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
              :content="logifier_id(scopeg.row.group).name"
            >
              <avatar-holder
                :avatar-url="logifier_id(scopeg.row.group).logo_url"
                :display-name="logifier_id(scopeg.row.group).name"
                :width="27"
                :height="27"
              />
            </el-tooltip>
          </span>
          <span v-else>
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
              :content="$t('table.content.no_group')"
            >
              <span class="pictify-0" />
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <!-- NAME -->
      <el-table-column
        width="300"
        prop="name"
      >
        <template slot="header">
          {{ $t('table.header.qr_code_name') }}
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            transition="none"
            :open-delay="400"
            :hide-after="0"
            :content="$t('table.header.qr_info')"
          >
            <i class="material-icons md-12 help">help_outline</i>
          </el-tooltip>
        </template>
        <template slot-scope="scopec">
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            transition="none"
            :open-delay="400"
            :hide-after="0"
            :content="scopec.row.name"
          >
            <strong>{{ scopec.row.name | truncate(40) }} </strong>
          </el-tooltip>
        </template>
      </el-table-column>

      <!-- DATE -->
      <el-table-column
        align="center"
        sortable="custom"
        prop="created_at"
        :formatter="formatDate"
        :label="$t('table.header.creation_date')"
      />

      <!-- TOTAL SCANS -->
      <el-table-column
        align="right"
        prop="total_scans"
        :formatter="formatScans"
        :label="$t('table.header.total_scans')"
      />
    </el-table>
  </div>
</template>

<script>
import Vue from 'vue'
import AvatarHolder from '@/components/Common/AvatarHolder'
import { TableEmptyData } from '@/components/Common/Table'
import { logifier_id } from '@/mixins'

export default {
  name: 'ListLanding',
  components: {
    AvatarHolder,
    TableEmptyData
  },
  mixins: [logifier_id],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    emptyLabel: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentOverId: ''
    }
  },
  computed: {
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    }
  },
  methods: {
    goToDetail (row) {
      this.$router.push({ name: 'qr-detail', params: { qrId: row.id }})
    },
    orderBy (val) {
      let orderBy = null
      if (val.order) {
        orderBy = val.prop
        if (val.order === 'descending') {
          orderBy = `-${orderBy}`
        }
      }
      this.$emit('sort-by', orderBy)
    },
    formatDate (row) {
      return Vue.options.filters.formatDate(row.created_at, this.$i18n.locale, this.userTimezone, this.$t('global.date_format'))
    },
    formatScans (row) {
      return Vue.options.filters.formatNumber(row.total_scans)
    },
    showOpen (row) {
      this.currentOverId = row.id
    },
  }
}
</script>

<style lang='scss'>
@import '@/assets/styles/_variables.scss';

.table-qr table th {
  padding-left: 10px;
}
.table-qr {
  .openpage {
    color: #858f96;
    i {
      color: #858f96 !important;
    }
  }
  .help {
    color: $--text-light;
    font-size: 13px;
    vertical-align: bottom;
    margin-left: 5px;
    opacity: 0.5;
    cursor: default;
  }
}
</style>
