<template>
  <div class="legende">
    <ul>
      <li
        v-for="(label, index) in labels"
        :key="index"
      >
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          transition="none"
          :open-delay="400"
          :hide-after="0"
          :content="$t(`labels.${label}_tip`)"
        >
          <span
            class="casecolor"
            :style="'background-color:' + $options.filters.colorize(label) + ';'"
          />
        </el-tooltip>
        <span v-if="prefix">
          {{ $t(`${prefix}.${label}`) }}
        </span>
        <span v-else>
          {{ $t(`labels.${label}`) }}
        </span>      
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'GraphLegend',
  props: {
    labels: {
      type: Array,
      default: () => {}
    },
    position: {
      type: String,
      default: "right"
    },
    prefix: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.legende {
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      line-height: 20px;
      height: 20px;
      vertical-align: middle;
      margin-bottom: 1rem;
    }
  }

  ul[position="bottom"] {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left:100px;
    margin-right:100px;
    justify-content: space-evenly;

    li {
      margin-right: 1rem;
      .casecolor {
        margin-right: 5px;
      }
    }
  }
  .casecolor {
    vertical-align: text-bottom;
    margin-right: 10px;
    display: inline-block;
    border-radius: 2px;
    width: 16px;
    height: 16px;
  }
}
</style>
