<template>
  <div class="kpi">
    <div class="picto">
      <i
        v-if="data.name === 'click' || data.name === 'total_clicks' || data.name === 'page_click' || data.name === 'click_rate'"
        class="material-icons md-18 ads_click"
      >ads_click</i>
      <i
        v-if="data.name === 'unsubscribe'"
        class="material-icons md-24"
        style="color: #EF4836;"
      >close</i>
      <i
        v-if="data.name === 'open'"
        class="material-icons md-18"
      >drafts</i>
      <i
        v-if="data.name === 'delivery'"
        class="material-icons md-24"
      >check</i>
      <i
        v-if="data.name === 'send'"
        class="material-icons md-24"
      >send</i>
      <i
        v-if="data.name === 'orders' || data.name === 'total_conversions' || data.name === 'conversion_rate' || data.name === 'articles'"
        class="material-icons md-24"
      >shopping_cart</i>
      <i
        v-if="data.name === 'conversion'"
        class="material-icons md-24"
      >flash_on</i>
      <i
        v-if="data.name === 'revenue'"
        class="material-icons md-24"
      >attach_money</i>
      <i
        v-if="data.name === 'sending'"
        class="material-icons md-24"
      >attach_money</i>
      <i
        v-if="data.name === 'acknowledged'"
        class="material-icons md-24"
      >send</i>
      <i
        v-if="data.name === 'sms'"
        class="material-icons md-24"
      >send</i>
      
      <i
        v-if="data.name === 'visit'"
        class="material-icons md-24"
      >present_to_all</i>
      <i
        v-if="data.name === 'total_visits'"
        class="material-icons md-24"
      >present_to_all</i>
      <i
        v-if="data.name === 'reach'"
        class="material-icons md-24"
      >ic_trending_up</i>
      <i
        v-if="data.name === 'buyers'"
        class="material-icons md-24"
      >group</i>
      <img
        v-if="data.name === 'average'"
        class="w-24"
        src="../../../assets/svg/icons/ic_amplification.svg"
        alt="Average image"
      >
      <img
        v-if="data.name === 'total_scans'"
        class="w-24"
        src="../../../assets/svg/icons/qrcode-scan.svg"
      >
      <img
        v-if="data.name === 'push_open' || data.name === 'push_unique_opens'"
        src="../../../assets/svg/icons/open.svg"
        alt="Open image"
      >
      <i
        v-if="data.name === 'total_revenue' || data.name === 'average_revenue_per_send'"
        class="material-icons md-24"
      >monetization_on</i>
      <i
        v-if="data.name === 'average_basket'"
        class="material-icons md-24"
      >shopping_basket</i>
    </div>
    <p
      v-if="data.kpi_value !== null && isPercentageValue"
      class="kpi_value"
    >
      <span v-if="data.name === 'reach'">+</span>
      {{ data.kpi_value * 100 | round2dec }}%
    </p>
    <p
      v-else-if="data.kpi_value !== null && isIntegerValue"
      class="kpi_value"
    >
      {{ data.kpi_value | formatNumber }}
    </p>
    <p
      v-else-if="data.kpi_value !== null && isCurrencyValue"
      class="kpi_value"
    >
      {{ data.kpi_value | formatNumber("0,0.00") }} {{ currency }}
    </p>
    <p
      v-else
      class="kpi_value"
    >
      -
    </p>
    <p class="kpi_label">
      {{ $t('kpi.' + channel + '.' + data.name +'_rate') }}
      <el-tooltip
        class="item"
        effect="dark"
        placement="top"
        transition="none"
        :open-delay="400"
        :hide-after="0"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          slot="content"
          v-html="$t(`${'kpi.' + channel + '.' + data.name +'_tip'}`)"
        />
        <!--eslint-enable-->
        <i class="material-icons md-12 help">help_outline</i>
      </el-tooltip>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ChartKpi',
  props: {
    currency: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => {}
    },
    channel: {
      type: String,
      required: true
    }
  },
  computed: {
    isPercentageValue() {
      return ![
        'send',
        'total_clicks',
        'total_visits',
        'total_scans',
        'push_unique_opens',
        'average',
        'total_conversions',
        'total_revenue',
        'average_revenue_per_send',
        'average_basket',
        'articles',
        'buyers'
      ].includes(this.data.name)
    },
    isIntegerValue() {
      return [
      'send',
      'total_clicks',
      'total_visits',
      'total_scans',
      'push_unique_opens',
      'average',
      'total_conversions',
      'articles',
      'buyers'
      ].includes(this.data.name)

    },
    isCurrencyValue() {
      return [
        'total_revenue',
        'average_revenue_per_send',
        'average_basket',
      ].includes(this.data.name)
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.w-24 {
  width: 24px
}
.kpi {
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #d2e8e8;
  border-radius: 3px;
  width: 135px;
  height: 135px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  justify-content: center;
  align-items: center;

  .ads_click {
    font-size: 20px;
  }

  .picto {
    // flex: 1;
    color: $--color-secondary;

    // fix icon 'ic_trending_up' and all future wrongly formatted icons
    // where the width is > to 24px
    max-width: 24px;
    display: flex;
    overflow: hidden;
    justify-content: flex-end;
  }
  .kpi_value {
    font-size: 18px;
    font-weight: 600;
    margin: 0.5rem 0;
    // flex: 1;
  }
  .kpi_label {
    color: $--text-light;
    font-size: 13px;
    vertical-align: bottom;
    // flex: 1;
    .help {
      margin-left: 1px;
      opacity: 0.5;
      vertical-align: middle;
      cursor: default;
    }
  }
  @media only screen and (max-width: $--mobile-width) {
    width: 100%;
    margin: 1rem 0;
  }
}
</style>
