<template>
  <div
    class="wrapper"
    :class="{'full-width' : fullWidth, 'hide-bg': $route.meta.hide_custom_bg }"
  >
    <AppHeader :scrolled="headerScrolled" />
    <main
      id="app__container"
      refs="appContainer"
      @scroll.passive="handleScroll"
    >
      <router-view />
    </main>
    <SnapcallSupport
      v-if="showSnapcall"
      :snapcall-url="snapcallUrl"
      :show-snapcall="showSnapcall"
      @end-snapcall="endCall"
    />
  </div>
</template>

<script>
import { debounce as _debounce  } from 'lodash-es'
import AppHeader from '@/components/Common/AppHeader'
import { SnapcallSupport } from '@team-crm-automation/nmp-components-vue2'

export default {
  components: {
    AppHeader,
    SnapcallSupport
  },
  data () {
    return {
      headerScrolled: false
    }
  },
  computed: {
    fullWidth () {
      return this.$store.getters['app/fullWidth']
    },
    showSnapcall() {
      return this.$store.getters['snapcall/showSnapcall']
    },
    snapcallUrl () {
      return `/snapcall.html?bid=${this.$store.getters['snapcall/snapcallId']}`
    },
  },
  methods: {
    handleScroll: _debounce(function (e) {
      this.headerScrolled = e.target.scrollTop > 0
    }, 10),
    endCall() {
      this.$store.dispatch('snapcall/endSnapcall')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.wrapper {
  height: 100vh;
  transition: all 400ms;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &.hide-bg {
    background: $--main-bg;
  }
  &.full-width {
    background-color: $--main-bg;
  }
}

#app__container {
  position: relative;
  margin: auto;
  margin-top: 48px;
  overflow-y: scroll;
  height: calc(100vh - 48px) !important;
}
.wrapper.full-width #app__container {
  width: 100%;
  max-width: 100%;
}

.snapcall-support {
  .el-button {
    min-width: unset;
    width: 20px;
  }
}
</style>
