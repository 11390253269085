<template>
  <header class="header-email-detail page-header">
    <div class="row">
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <HeaderSelectIdm
      v-if="relatedCampaigns.length > 1"
      :campaigns="relatedCampaigns"
      :campaign-type="campaignType"
      @change-campaign="handleUpdateRoute"
    />
    <div class="entete block-meta">
      <div class="left">
        <div class="er subject">
          <i class="material-icons md-22">format_quote</i>
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            transition="none"
            :open-delay="400"
            :hide-after="0"
            :content="campaign.subject"
          >
            <span>{{ campaign.subject }}</span>
          </el-tooltip>
        </div>

        <div class="er sending-date">
          <span class="campaign-type">
            <i class="material-icons md-22">schedule</i>
            {{ $t(`detail.header.${campaignType}`) }}
          </span>
          <span class="date-information">
            {{ getDateInformation() }}
          </span>
        </div>
        <div
          v-if="expeditors.length"
          class="er expeditors"
        >
          <div class="expeditor-name">
            <i class="material-icons md-24">perm_identity</i>
            {{ expeditors[0].name }}
          </div>
          <div class="expeditor-email">
            {{ getFormattedEmail(expeditors[0].email) }}
            <el-dropdown
              class="er"
              trigger="click"
              @visible-change="isOpen"
            >    
              <div
                v-if="expeditors.length > 1"
                class="tag more"
              >
                +{{ expeditors.length - 1 }}
                <i class="material-icons">
                  expand_more
                </i>
              </div>
              <el-dropdown-menu v-if="expeditors.length > 1">
                <el-dropdown-item
                  v-for="(exp, index) in campaign.expeditors"
                  :key="index"
                  :divided="index > 0"
                  disabled
                >
                  {{ exp.sender_domain }}
                  <div class="expeditor-name">
                    {{ formatExpeditorInfo(exp) }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>

      <div class="right">
        <DatePicker
          v-if="[1, 5, 7003, 7005].includes(campaign.campaign_type)"
          @updated="() => $emit('updated-range-date')"
        />
        <SplitSelector
          :header-splits="headerSplits"
          :current-split="firstCurrentSplit"
          channel="email"
          @select-split="(s) => $emit('split-updated', s)"
        />
        <TableSelector
          channel="email"
          :header-splits="headerSplits"
          @select-split="(s) => $emit('select-table-split', s)"
        />
        <CampaignDataExportButton
          v-if="hasExportSendingDataPerm && hasModuleDataExport"
          :campaign="campaign"
          :channel-id="emailChannelId"
          :campaign-id="campaignId"
          :campaign-type="campaignType"
          @click-download="(p) => $emit('generate-export', p)"
          @click-view-exports="() => showDataExportDrawer = true"
        />
        <DownloadButton
          v-else
          :loading="downloadLoading"
          @click-download="(p) => $emit('generate-export', p)"
        />
        <CampaignDataExportDrawer
          v-if="hasModuleDataExport"
          :show-drawer="showDataExportDrawer && campaign.id != undefined && hasExportSendingDataPerm"
          :campaign="campaign"
          :channel-id="emailChannelId"
          @update-show-drawer="() => showDataExportDrawer = false"
        />
      </div>
    </div>
  </header>
</template>

<script>
import config from '@/config'
import {
  DatePicker,
  DownloadButton,
  SplitSelector,
  TableSelector
} from '@/components/Common/Form'
import {CampaignDataExportButton, CampaignDataExportDrawer} from '@/components/Common/Campaign'
import Breadcrumb from '@/components/Common/Breadcrumb'
import HeaderSelectIdm from '@/components/Common/SelectIdm'

export default {
  name: 'PartsHeaderEmailDetail',
  components: {
    SplitSelector,
    TableSelector,
    DatePicker,
    DownloadButton,
    Breadcrumb,
    HeaderSelectIdm,
    CampaignDataExportButton,
    CampaignDataExportDrawer
  },
  props: {
    currentSplit: {
      type: Array,
      required: true
    },
    downloadLoading: {
      type: Boolean,
      default: false
    },
    headerSplits: {
      type: Object,
      default: () => {}
    },
    campaign: {
      type: Object,
      default: () => {}
    },
    campaignMeta: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      expeditor: '',
      arrowup: false,
      emailChannelId: config.CHANNELS.EMAIL,
      showDataExportDrawer: false
    }
  },
  computed: {
    campaignId () {
      return parseInt(this.$route.params.campaignId)
    },
    campaignType () {
      if (this.campaign.campaign_type === config.EMAIL_CHANNEL_TAGS.automatic) {
        return 'automatic'
      } else if (this.campaign.campaign_type === config.EMAIL_CHANNEL_TAGS.transactional) {
        return 'transactional'
      }
      return 'one_shot'
    },
    sendingDate () {
      return this.campaignType === 'one_shot' ? this.campaign.start_date : this.campaign.end_date
    },
    relatedCampaigns () {
      if (!Object.keys(this.campaignMeta).length || !this.campaignMeta["related_campaigns"]) {
        return []
      }
      return [this.campaignId, ...this.campaignMeta["related_campaigns"]]
    },
    firstCurrentSplit () {
      return this.currentSplit.length > 0 ? this.currentSplit[0] : null
    },
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    groupName () {
      return this.campaign.group ? `(${this.campaign.group})` : ''
    },
    expeditors () {
      if (!this.campaign.expeditors || !this.campaign.expeditors.length) {
        return ''
      }

      let expeditors = this.campaign.expeditors
      if (this.firstCurrentSplit > 0 && this.firstCurrentSplit.type === 'extra_data') {
        expeditors = expeditors.filter(exp => exp.sender_domain_id === this.firstCurrentSplit.split)
      }
      return expeditors //.map(this.formatExpeditorInfo)
    },
    breadcrumbs () {
      const capitalize = this.$options.filters.capitalize
      return [
        {
          state: 'email-list',
          name: `${capitalize(this.$tc('global.email_campaign', 2))} ${this.groupName}`
        },
        {
          state: null,
          name: `${this.$route.params.campaignId} - ${this.campaign.campaign_name}`
        }
      ]
    },
    hasExportSendingDataPerm () {
      return this.$store.getters['session/hasPermission']('dashboardly:user:export-sending-data')
    },
    hasModuleDataExport () {
      return this.$store.getters['session/hasModuleDataExport']
    },
   
  },
  mounted() {
    this.expeditor = this.expeditors[0]
    // init on mounted to make sure component is correctly mounted
    this.showDataExportDrawer = this.$route.query.drawer == "true" ? true : false
  },
  methods: {
    isOpen(open) {
      this.arrowup = open
      return open
    },
    formatExpeditorInfo (expeditor) {
      return `${expeditor.name} <${expeditor.email}>`
    },
    handleUpdateRoute(campaignId) {
      this.$emit('change-campaign', campaignId)
    },
    getFormattedEmail(email) {
      return `<${email}>`
    },
    formatDate(date) {
      return this.$options.filters.formatDate(date, this.$i18n.locale, this.userTimezone, this.$t('global.date_format'))
    },
    getDateInformation() {
      if (this.sendingDate) {
        switch (this.campaignType) {
          case 'automatic':
          case 'transactional':
            return `${this.$t('detail.header.campaign_first_sent')} : ${this.formatDate(this.campaign.start_date)} | ${this.$t('detail.header.campaign_last_sent')} : ${this.formatDate(this.campaign.end_date)}`
          case 'one_shot':
            return `${this.$t('detail.header.campaign_sent')} : ${this.formatDate(this.campaign.start_date)}`
        }
      } else {
        return this.$t('detail.header.campaign_not_sent')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.header-email-detail {
  .block-meta {
    border-radius: 3px;
    padding: 16px;
    background-color: $--color-header-detail-dark-bg;
    background: repeating-linear-gradient(
      -45deg,
      $--color-header-detail-dark-bg 0%,
      $--color-header-detail-dark-bg 37%,
      $--color-header-detail-bg 37%,
      $--color-header-detail-bg 100%
    );
  }

  .expeditors-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    color: $--text-primary;
    font-size: 14px;
    font-weight: 400;

    .tag {
      display: flex;
    }
    .more {
      margin-left: 0.5rem;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column;
    }
  }
  .entete {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 162px;
    gap: 10px;

    .left {
      .er {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 4px;
        line-height: 24px;
        // height: 24px;
        &:last-child {
          margin-bottom: 0px;
        }
      }

      i {
        font-size: 24px;
        margin-right: 10px;
        color: $--color-primary;
      }

      .subject {
        overflow: hidden;
        max-height: 48px;
        .el-tooltip {
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .custom_icon {
          display: inline-block;
        }
      }
      .sending-date {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .campaign-type {
          display: flex;
          flex-direction: row;
          line-height: 24px;
          font-weight: bold;
        }
        .date-information {
          margin-left: 34px;
        }
      }
      .er.expeditors {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .expeditor-name, .expeditor-email {
        display: flex;
        flex-direction: row;
      }
      .expeditor-name {
        font-weight: bold;
      }
      .expeditor-email {
        margin-left: 34px;
        gap: 8px;
        .el-dropdown {
          color: $--text-primary;
         
        }
        .more {
          width: 55px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding-left: 4px;
          gap: 8px;
          font-size: 16px;
          i {
            margin-right: 0;
            color: $--text-primary;
          }
        }
      }

      .el-icon-arrow-down,
      .el-icon-arrow-up {
        line-height: 20px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}
</style>
