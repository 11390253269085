<template>
  <section class="clickmap">
    <div class="row">
      <!-- Map + Device switch  -->
      <div class="col-left">
        <div class="last-updated">
          {{ $t('landing.last_updated', {date: updatedDate, time: updatedTime}) }}
        </div>
        <div class="map">
          <CampaignMapReporting
            :html="clickMapHtml"
            :stats="clickMapLinks"
            :page="{ id: landingId }"
            :displayed-links="links"
            :is-loading-page="false"
            :size="previewType"
            channelapi="landing"
          />
        </div>
      </div>

      <!-- Table + Filter -->
      <div class="col-right">
        <div class="menu">
          <el-dropdown
            trigger="click"
            @command="applyFilter"
          >
            <el-button
              class="custom-select"
              type="secondary"
            >
              {{ $t(linkFilter.selected.label) }}
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(filter, index) in linkFilter.list"
                :key="index"
                :command="filter"
              >
                {{ $t(filter.label) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="table">
          <el-table
            :data="links"
            :empty-text="$t('detail.no_data')"
            :summary-method="getSummaries"
            style="width: 100%"
            show-summary
          >
            <el-table-column
              sortable
              label="ID"
              width="55"
              align="center"
              prop="link_number"
            />
            <el-table-column
              prop="link"
              align="left"
              :label="$t('table.header.link')"
            >
              <template slot-scope="scopec">
                <div class="linkMap">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top"
                    transition="none"
                    :open-delay="400"
                    :hide-after="0"
                    :content="scopec.row.link"
                  >
                    <a
                      :href="scopec.row.link"
                      target="_blank"
                    >
                      {{ scopec.row.link }}
                    </a>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              width="90"
              align="right"
              prop="total_clicks"
              :formatter="formatNumber"
              :label="$t('table.header.total_click')"
            />
            <el-table-column
              sortable
              width="80"
              align="right"
              prop="percentage"
              :formatter="round2dec"
              :label="$t('table.header.total_percent')"
            />
          </el-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CampaignMapReporting } from '@/components/Common/Campaign'

export default {
  components: {
    CampaignMapReporting
  },
  data () {
    return {
      previewType: 'desktop',
      map: {
        html: '',
        stats: []
      },
      linkFilter: {
        selected: {
          label: 'BUTTON.ALL_LINKS',
          value: 'all'
        },
        list: [
          {
            label: 'BUTTON.ALL_LINKS',
            value: 'all'
          },
          {
            label: 'BUTTON.TOP_FIVE_LINKS',
            value: 'top_five'
          },
          {
            label: 'BUTTON.TOP_TEN_LINKS',
            value: 'top_ten'
          }
        ]
      }
    }
  },
  computed: {
    landingId () {
      return this.$route.params.landingId
    },
    rangeDate () {
      return this.$store.getters['app/rangeDate']
    },
    clickMapLinks () {
      return this.$store.getters['map/clickMap'].links || []
    },
    clickMapHtml () {
      return this.$store.getters['map/clickMap'].html || ''
    },
    headerMeta () {
      return this.$store.getters['landingDetail/headerMeta']
    },
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    updatedDate () {
      const _date = this.headerMeta.last_publish_date || this.headerMeta.first_publish_date
      return this.$options.filters.formatDate(_date, this.$i18n.locale, this.userTimezone, this.$t('global.date_format'))
    },
    updatedTime () {
      const _time = this.headerMeta.last_publish_date || this.headerMeta.first_publish_date
      return this.$options.filters.formatTime(_time, this.$i18n.locale, this.userTimezone, this.$t('global.date_format'))
    },
    /**
     * List of links from store filtered
     * depending component configuration
     */
    links () {
      const storedLinks = this.clickMapLinks || []
      const filter = this.linkFilter.selected.value

      switch (filter) {
        case 'top_five':
          return storedLinks.sort((a,b) => (b.total_clicks >= a.total_clicks)  ? 1 : -1).slice(0, 5)
        case 'top_ten':
          return storedLinks.sort((a,b) => (b.total_clicks >= a.total_clicks)  ? 1 : -1).slice(0, 10)
        default:
          return storedLinks
      }
    }
  },
  methods: {
    /**
     * Generate links table footer values
     * @param  {Object} params (element params)
     * @return {Array}         (Values to display)
     */
    getSummaries (params) {
      const { columns, data } = params
      const sums = []
      let reducer = (acc, val) => acc + val
      let totalClicks, totalPercent

      columns.forEach((column, index) => {
        const property = column.property
        switch (property) {
          case 'link_number':
            sums[index] = this.$t('table.footer.total')
            break
          case 'url':
            sums[index] = ''
            break
          case 'total_clicks':
            totalClicks = data.map(el => el[property]).reduce(reducer, 0)
            sums[index] = this.$options.filters.formatNumber(totalClicks)
            break
          case 'percentage':
            totalPercent = data.map(el => el[property]).reduce(reducer, 0)
            sums[index] = `${this.$options.filters.round2dec(totalPercent * 100)}%`
            break
        }
      })

      return sums
    },
    /**
     * Apply filter config (@command from element-ui)
     * @param  {Object} newFilter (Filter: label, value)
     */
    applyFilter (newFilter) {
      this.linkFilter.selected = newFilter
    },
    /**
     * Percentage formatter for link table
     * @param  {Object} row (row data)
     * @return {String}     (formatted percentage)
     */
    round2dec (row) {
      return `${this.$options.filters.round2dec(row.percentage * 100)}%`
    },
    /**
     * Total clicks formatter for link table
     * @param  {Object} row (row data)
     * @return {String}     (formatted total clicks)
     */
    formatNumber (row) {
      return this.$options.filters.formatNumber(row.total_clicks)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.clickmap {
  padding-bottom: 3rem;
  .row {
    display: flex;
    justify-content: space-between;
    .col-left {
      width: 800px;
      text-align: center;
      position: relative;
      .last-updated {
        line-height: 40px;
        margin-bottom: 1.5rem;
        color: $--text-light;
      }
      .switch {
        margin-bottom: 1.2rem;
      }
    }
    .col-right {
      width: 390px;
      text-align: center;
      position: relative;
      margin-left: 10px;
      .menu {
        margin-bottom: 1.5rem;
      }
      .table {
        border: 1px solid #DCDFE6;
        .cell {
          font-size: 11px;
        }
        th.is-left .cell {
          justify-content: flex-start;
          padding: 0 10px;
        }
        th.is-center .cell {
          justify-content: center;
        }
        .el-table__footer-wrapper {
          font-weight: 600;
        }
        .linkMap {
          max-height: 45px;
          overflow: hidden;
          a {
            color: $--text-dark;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            margin: 0 auto;
            line-height: 1.4;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
