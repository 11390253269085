<template>
  <div
    class="block-param bp-landing bp-generic"
    :class="{'hidden': !isData,'bp-email': channel === 'email', 'bp-sms': channel === 'sms'}"
  >
    <div class="row">
      <h2>{{ $t(`kpi.${channel}.${kpiskey}`) }}</h2>
      <el-select
        v-if="isData"
        v-model="currentLpId"
        :placeholder="$t('labels.landing_page')"
      >
        <el-option
          v-for="(item, index) in kpisvalue"
          :key="item.id"
          :label="item.lp_name"
          :value="index"
        />
      </el-select>
    </div>

    <div
      v-if="isData"
      class="kpi_desc"
    >
      {{ $t('kpi.landing.campaign_desc') }}
      <router-link
        :to="{name: 'landing-detail-overview', params: { landingId: kpisvalue[currentLpId].id }}"
        active-class="header-menu-link-active"
        class="header-menu-link"
      >
        {{ $t('kpi.landing.full_report') }}
      </router-link>.
    </div>

    <div
      v-if="isData"
      class="infos-container"
    >
      <div
        v-if="kpisvalue[currentLpId] && kpisvalue[currentLpId].data"
        class="kpis"
      >
        <ChartKPI
          v-for="impKpi in importantKpiList(kpisvalue[currentLpId].data)"
          :key="impKpi.key"
          :channel="'landing'"
          :data="{
            name: impKpi.name,
            kpi_value: impKpi.value
          }"
        />
      </div>
      <div v-else>
        {{ kpisvalue[currentLpId] }}
      </div>

      <!-- Infos -->
      <div
        v-if="kpisvalue[currentLpId] && kpisvalue[currentLpId].data"
        class="infos"
      >
        <div
          v-for="(kpi, index) in kpisvalue[currentLpId].data"
          :key="index"
          class="tr"
          :class="{'hidden': isImportantKpi(kpi.key) }"
        >
          <div
            v-if="!isImportantKpi(kpi.key)"
            class="td-l"
          >
            <span v-if="kpi.key === 'soft_bounces' || kpi.key === 'hard_bounces'">
              <i class="material-icons md-12 help">subdirectory_arrow_right</i>
            </span>
            {{ $t(`kpi.landing.${kpi.key}`) }}
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              transition="none"
              :open-delay="400"
              :hide-after="0"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                slot="content"
                v-html="$t(`kpi.landing.${kpi.key}_tip`)"
              />
              <!-- eslint-enable -->
              <i class="material-icons md-12 help">help_outline</i>
            </el-tooltip>
          </div>
          <div class="td-r">
            {{ formattingValue(kpi) }}
          </div>
        </div>
      </div>
      <div v-else>
        {{ kpisvalue[currentLpId] }}
      </div>
    </div>
    <div v-else>
      <p class="nodata">
        {{ $t('kpi.landing.no_data') }}
      </p>
    </div>
  </div>
</template>

<script>
import { ChartKPI } from '@/components/Common/Chart'

export default {
  name: 'BlockLanding',
  components: { ChartKPI },
  props: {
    channel: {
      type: String,
      required: true
    },
    kpiskey: {
      type: String,
      required: true
    },
    kpisvalue: {
      type: Array,
      default: () => []
    },
    kpis: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isData: this.kpisvalue.length > 0,
      currentLpId: 0,
      dataselect: ''
    }
  },
  methods: {
    /**
     * Check if they key is a KPI
     * @param {String} key (kpi key)
     * @return {Boolean}
     */
    // isKpi(key) {
    //   return !this.notKpi.includes(key)
    // },
    /**
     * Check if it's an important KPI
     * @param  {String} key (kpi key)
     * @return {Boolean}
     */
    isImportantKpi (key) {
      return this.kpis.important.includes(key)
    },
    /**
     * Filter kpis list for important ones
     * @param  {Array} kpis (collection of kpis object)
     * @return {Array} (Should not exeed size of 2)
     */
    importantKpiList (kpis) {
      return kpis.filter(kpi => this.isImportantKpi(kpi.key))
    },
    /**
     * Formatting values to make them human readable
     */
    formattingValue (kpi) {
      if (!Object.prototype.hasOwnProperty.call(kpi, 'key')) {
        /* eslint-disable no-console */
        console.error('KPI without key', kpi)
        /* eslint-enable no-console */
        return
      }
      const isPercentage = kpi.key.includes('_rate') && kpi.value !== null
      const isNumber = !isPercentage && !isNaN(kpi.value) && kpi.value !== null
      const isNotApplicable = kpi.value === null

      switch (true) {
        case isPercentage:
          return `${this.$options.filters.round2dec(kpi.value * 100)}%`
        case isNumber:
          return this.$options.filters.formatNumber(kpi.value)
        case isNotApplicable:
          return 'N/A'
        default:
          return kpi.value
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-landing {
  .nodata {
    color: $--text-light;
    text-align: center;
    margin-bottom: 2rem;
  }
  .kpi_desc {
    a {
      text-decoration: underline;
      color: $--text-light;
    }
  }
}
</style>
