<template>
  <div class="block-param bp-graph bp-landing">
    <div class="row">
      <h2>
        {{ $t('kpi.landing.visits_per_device') }}
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          transition="none"
          :open-delay="400"
          :hide-after="0"
        >
          <div slot="content">
            {{ $t('labels.opensperdevice_tip') }}
          </div>
          <i class="material-icons md-12 help">help_outline</i>
        </el-tooltip>
      </h2>
      <el-select
        v-if="dataselect"
        :value="dataselect"
      />
    </div>

    <div class="block-devices">
      <div
        v-if="devices"
        class="chart-container"
      >
        <ChartDonut
          v-if="ifChartData"
          :chart-data="chartDevices"
          :options="chartOptions"
        />
        <div
          v-else
          class="no-data"
        >
          <strong>{{ $t('detail.no_data') }}</strong>
        </div>
      </div>
      <div class="legende-container">
        <GraphAutoLegend
          :labels="chartDevices.labels"
          :tooltips="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChartDonut } from '@/components/Common/Chart'
import { GraphAutoLegend } from '@/components/Common/Graph'

export default {
  name: 'BlockGraphLanding',
  components: { ChartDonut, GraphAutoLegend },
  data () {
    return {
      dataselect: '',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const currentValue = dataset.data[tooltipItem.index]
              return currentValue
            },
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index].label
            }
          }
        }
      }
    }
  },
  computed: {
    devices () {
      return this.$store.getters['landingDetail/devices'] || []
    },
    chartDevices () {
      return this.customizer(this.devices, 'Devices')
    },
    ifChartData () {
      for (let i in this.chartDevices.datasets[0].data) {
        if (this.chartDevices.datasets[0].data[i] > 0) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    customizer (original, _label) {
      let bgc = []
      let labelTrad = []
      let datasets = []

      const opens = original.opens

      if (opens && opens.labels) {
        for (let i in opens.labels) {
          bgc.push(this.$options.filters.autocolorize(i))
          labelTrad.push({
            label: this.$t(`${'labels.' + opens.labels[i].toLowerCase()}`),
            tooltip: this.$t(`${'labels.' + opens.labels[i].toLowerCase() + '_tip'}`)
          })
        }
      }
      if (opens && opens.datasets) {
        datasets = opens.datasets.data
      }
      return { labels: labelTrad, datasets: [{ label: _label, data: datasets, backgroundColor: bgc }] }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-graph {
  .row {
    margin-bottom: 1rem;
    h2 {
      i {
        display: inline-block;
        color: $--text-light;
        font-size: 12px;
        height: 15px;
        vertical-align: text-bottom;
      }
    }
  }
}
.block-devices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .chart-container {
    position: relative;
    width: 250px;
    height: 250px;
    padding: 0;
    margin: 0 2rem;
    border: none;
  }
  .no-data {
    position: relative;
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $--text-light;
    border-radius: 50%;

    strong {
      text-align: center;
      line-height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 30px;
      margin-left: -50%;
      margin-top: -15px;
    }
    &::before {
      content: "";
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 50%;
      background-color: white;
      border-radius: 50%;
    }
  }
  .legende-container {
    flex: 0.8;
    .legende {
      justify-content: start;
    }
  }
}
</style>
